import axios, { AxiosResponse } from "axios";
import { getAdminAccessToken } from "./authFunctions";
import jwt_decode from "jwt-decode";
import { TGatewayToken } from "./types";

export const downloadBastionRdp = async (
  bastionProxyUrl: string,
  vmResourceId: string,
  fileName: string
) => {
  let accessToken = await getAdminAccessToken();
  let rdpFile: AxiosResponse<string, any> | undefined = undefined
  try {
    rdpFile = await axios.get<string>(
      `${bastionProxyUrl}/RDPProxy?vmResourceId=${vmResourceId}`,
      {
        headers: { Authorization: `Bearer ${accessToken}`},
      }
    );
  } catch (error) {
    throw new Error("Unable to generate RDP File: "+ error)
  }
  
  
  const rdpContent = rdpFile.data.replace("use multimon:i:1", "use multimon:i:0");
  const gatewayAccessRegEx = /gatewayaccesstoken:s:.*/g;

  let gatewayAccessRegExExecArray = gatewayAccessRegEx.exec(rdpContent);
  let expirationDate: Date | undefined = undefined;
  if (gatewayAccessRegExExecArray) {
    try {
      let gatewayAccessToken = gatewayAccessRegExExecArray[0].replace(
        "gatewayaccesstoken:s:",
        ""
      );
      let decodedAccessToken = jwt_decode<TGatewayToken>(gatewayAccessToken);
      if (decodedAccessToken && decodedAccessToken.exp) {
        expirationDate = new Date(decodedAccessToken.exp * 1000);
      }
    } catch (error) {
      throw new Error("Could not get expiry date of rdp access: " + error);
    }
  }

  if (expirationDate) {
    fileName = `${fileName}_exp_${expirationDate.getFullYear()}-${
      expirationDate.getMonth() + 1
    }-${expirationDate.getDate()}_${expirationDate.getHours()}-${expirationDate.getMinutes()}-${expirationDate.getSeconds()}`;
  }

  // file object
  const file = new Blob([rdpContent], { type: "text/plain" });
  // anchor link
  const element = document.createElement("a");
  element.href = URL.createObjectURL(file);
  element.download = `${fileName}.rdp`;
  // simulate link click
  document.body.appendChild(element);
  // Required for this to work in FireFox
  element.click();
};
