import React from "react";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import InstallDesktopIcon from "@mui/icons-material/InstallDesktop";
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import Logo from "../assets/images/logoSick.png";

import { Button, Grid, Stack } from "@mui/material";
import { useMsal } from "@azure/msal-react";

import environment from "../assets/environmentSettings.json";

export const Menu = () => {
  const versionNumber = environment.versionNumber

  const CurrentUserDisplay = () => {
    const account = useMsal().accounts[0];
  
    return (
      <Grid container sx= {{ display: "flex",alignItems:"center", height:"100%"}}>
        <PersonIcon />
        <Typography sx= {{fontSize: "18px"}}>{ account?.name}</Typography>
      </Grid>
    )
  }

  const SignOutButton = () => {
    const instance = useMsal().instance;
    return (
      <Button
        onClick={() => instance.logoutRedirect()}
        color="secondary"
      >
        <LogoutIcon />
      </Button>
    );
  }

  return (
    <AppBar position="static">
      <Container maxWidth={false}>
        <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
          <img className="menu__sick_logo" src={Logo} alt="SickLogo" />
          <Stack>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: "flex",
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
                width: "100%"
              }}
            >
              Enterprise Remote Web GUI

            </Typography>
            <Typography variant="subtitle1" noWrap sx={{ fontSize:12 }}>{versionNumber}</Typography>
          </Stack>
          <Grid container sx={{ justifyContent: "flex-end" }}>
            <Grid item><CurrentUserDisplay /></Grid>
            <Grid item><SignOutButton /></Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
