import React, { useEffect, useRef, useMemo, useState, ChangeEventHandler, ReactNode, SetStateAction } from "react";
import environment from "../assets/environmentSettings.json";
import {
  HashTable,
  TVMDetailedInformation,
  TVMSelection,
} from "../assets/ts/types";
import { downloadBastionRdp } from "../assets/ts/bastionFunctions";
import {
  getActiveBastionSessions,
  getResourceGroups,
  getSubscriptions,
  getSwitchState,
  getVMsDetailedInformation,
  getVMDetailedInformation,
  startVm,
  stopVm,
} from "../assets/ts/azureManagementFunctions";
import { groupBy } from "../assets/ts/extensionFunctions";

//azure-sdk-js
import { Subscription } from "@azure/arm-resources-subscriptions";
import { ResourceGroup } from "@azure/arm-resources";
import { BastionActiveSession } from "@azure/arm-network";

//MUI
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Snackbar from "@mui/material/Snackbar";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { Exception } from "sass";
import IconButton from "@mui/material/IconButton";
import { FormControl, Input, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";

const subscriptionDisplayNameRemoveRegex = new RegExp(
  environment.subscriptionDisplayNameRemoveRegex,
  "g"
);
const resourceGroupDisplayNameRemoveRegex = new RegExp(
  environment.resourceGroupDisplayNameRemoveRegex,
  "g"
);
const vmDisplayNameRemoveRegex = new RegExp(
  environment.vmDisplayNameRemoveRegex,
  "g"
);

const VM_STATE_UPDATE_INTERVAL_MS = 10000;

export const BastionGui = () => {
  const locations = ["EU","US"];
  const [open, setOpen] = React.useState(false);
  const [locationFilter, setLocationFilter] = React.useState([] as string[]);
  const [error, setError] = React.useState({err: false, errorInfo:""});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [subscriptions, setSubscriptions] = useState<Subscription[]>();
  const [defaultSubscription, setDefaultSubscription] = useState<Subscription>();
  const [resourceGroups, setResourceGroups] = useState<
    ResourceGroup[] | undefined
  >([]);
  const [vms, setVms] = useState<TVMDetailedInformation[] | undefined>([]);
  const vmsRef = useRef(vms);
  vmsRef.current = vms;
  const [selection, setSelection] = useState<TVMSelection>({
    subscription: undefined,
    resourceGroup: undefined,
    vm: undefined,
  });
  const [vmActiveSessionsHashTable, setVmActiveSessionsHashTable] = useState<
    HashTable<BastionActiveSession[]>
  >({});
  

  const updateSubscriptions = () => {
    getSubscriptions().then((subscriptions) => {
      setSubscriptions(subscriptions);
      setDefaultSubscription(subscriptions.filter(s => s.displayName == environment.defaultSubscription)[0])
    }).catch(e => {
      setError({err: true, errorInfo: e.message})
    });
  };

  const updateResourceGroups = (subscription?: Subscription) => {
    let filteredResourceGroups: ResourceGroup[] = []
    if (!subscription || !subscription.subscriptionId) {
      setResourceGroups([]);
    } else {
      setResourceGroups(undefined);
      getResourceGroups(subscription.subscriptionId).then((resourceGroups) => {
        locationFilter.length == 0 ? filteredResourceGroups= [] :
        locationFilter.forEach(l=> {
          filteredResourceGroups = filteredResourceGroups.concat(resourceGroups.filter(rg => {return rg.name?.includes(l.toLowerCase())}))
          filteredResourceGroups = Array.from(new Set(filteredResourceGroups))
        })
        setResourceGroups(filteredResourceGroups);
        setSelection({
          subscription: subscription ?? subscriptions?.find(s => s.displayName === environment.defaultSubscription),
          resourceGroup: undefined,
          vm: undefined,
        });
      }).catch(e => {
        setError({err: true, errorInfo: e.message})
      });
    }
    updateVMs(subscription);
  };

  const updateVMs = (
    subscription?: Subscription,
    resourceGroup?: ResourceGroup
  ) => {
    setSelection({
      subscription: subscription,
      resourceGroup: resourceGroup,
      vm: undefined,
    });
    if (
      !subscription ||
      !subscription.subscriptionId ||
      !resourceGroup ||
      !resourceGroup.name
    ) {
      setVms([]);
    } else {
      setVms(undefined);
      getVMsDetailedInformation(subscription, resourceGroup).then((vms) => {
        vms.forEach((vm) => {
          vm = {
            ...vm,
            subscription: subscription,
            resourceGroup: resourceGroup,
          };
          registerStateUpdateForVm(vm);
        });
        setVms(vms)
      }).catch(e => {
        setError({err:true, errorInfo: e.message})
      });
    }
  };

  const updateActiveBastionSessions = () => {
    getActiveBastionSessions(
      environment.bastionSubscriptionId,
      environment.bastionResourceGroupName,
      environment.bastionHostName
    ).then((activeSessions) => {
      console.log(activeSessions);
      let newVmActiveSessionsHashTable: HashTable<BastionActiveSession[]> =
        groupBy(activeSessions, (e) => e.targetResourceId ?? "UNKNOWN");
      setVmActiveSessionsHashTable(newVmActiveSessionsHashTable);
    }).catch(e => {
      setError({err: true, errorInfo: e.message})
    });
  };

  useEffect(() => {
    updateSubscriptions();
    //updateActiveBastionSessions();
  }, []);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleLocationSwitch = (event: SelectChangeEvent<unknown>, child: ReactNode)=> {
    setLocationFilter(event.target.value as SetStateAction<string[]>);
    updateResourceGroups(selection.subscription);
  }

  const generateCliCommand = (vmInfo: TVMDetailedInformation) => {
    let resourceGroupName = vmInfo.resourceGroup?.name;
    let vmResourceId = vmInfo.vm?.id;
    navigator.clipboard.writeText(`az network bastion rdp --name ${environment.bastionHostName} --resource-group ${resourceGroupName} --target-resource-id ${vmResourceId} --disable-gateway true`);
    setOpen(true);
  }

  const generateRdp = (vmInfo: TVMDetailedInformation) => {
    let subscriptionId = vmInfo.subscription?.subscriptionId;
    let resourceGroupName = vmInfo.resourceGroup?.name;
    let vmName = vmInfo.vm?.name;
    let vmResourceId = vmInfo.vm?.id;
    

    try {
      if (!subscriptionId || !resourceGroupName || !vmName || !vmResourceId) {
        throw new Error("Selection not initialized");
      }

      downloadBastionRdp(environment.bastionProxyUrl, vmResourceId, vmName);
    } catch(e) {
      setError({err:true,errorInfo: e.message})
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const registerStateUpdateForVm = (vm: TVMDetailedInformation) => {
    if (vm.vm.id) {
      if (vm.switchState.disabled) {
        setTimeout(() => {
          getVMDetailedInformation(
            vm.subscription,
            vm.resourceGroup,
            vm.vm.name!
          ).then((updatedVm) => {
            if (updatedVm && vmsRef.current) {
              let wasVMUpdated = false;
              let newVms = vmsRef.current.map((vmFromState) => {
                if (vmFromState.vm.id === updatedVm.vm.id) {
                  wasVMUpdated = true;
                  return updatedVm;
                } else {
                  return vmFromState;
                }
              });
              // If the vm is not displayed anymore to update we do not reschedule for update
              if (wasVMUpdated) {
                setVms((oldVms) => newVms);
                registerStateUpdateForVm(updatedVm);
              }
            } else {
              throw new Error("Updated element returned empty - deregistering update: " + vm.vm)
            }
          }).catch(e => {
            setError({err:true, errorInfo: e.message})
          });
        }, VM_STATE_UPDATE_INTERVAL_MS);
      }
    }
  };

  const handleSwitchChange = (i: number) => {
    if (
      !vms ||
      !vms[i] ||
      !vms[i].subscription ||
      !vms[i].subscription.subscriptionId ||
      !vms[i].vmInstanceView
    ) {
    } else {
      let vmIsRunning =
        vms[i].vmInstanceView.code?.toLowerCase() === "powerstate/running" ||
        vms[i].vmInstanceView.code?.toLowerCase() === "powerstate/starting";
      let powerStateVmInstanceView = vms[i].vmInstanceView;
      if (!vmIsRunning) {
        powerStateVmInstanceView.code = "PowerState/starting";
        vms[i].switchState = getSwitchState(powerStateVmInstanceView);
        setVms([...vms]);
        startVm(
          vms[i].subscription.subscriptionId!,
          vms[i].resourceGroup.name!,
          vms[i].vm.name!
        ).then(() => {
          powerStateVmInstanceView.code = "PowerState/running";
          vms[i].switchState = getSwitchState(powerStateVmInstanceView);
          setVms([...vms]);
        }).catch((e) => {
          setError({err: true, errorInfo: e.message});
          powerStateVmInstanceView.code = "PowerState/deallocated";
          vms[i].switchState = getSwitchState(powerStateVmInstanceView);
          setVms([...vms]);
        });
      } else {
        powerStateVmInstanceView.code = "PowerState/deallocating";
        vms[i].switchState = getSwitchState(powerStateVmInstanceView);
        setVms([...vms]);
        stopVm(
          vms[i].subscription.subscriptionId!,
          vms[i].resourceGroup.name!,
          vms[i].vm.name!
        ).then(() => {
          powerStateVmInstanceView.code = "PowerState/deallocated";
          vms[i].switchState = getSwitchState(powerStateVmInstanceView);
          setVms([...vms]);
        }
        ).catch(function (e) {
          setError({err: true, errorInfo: e.message});
          powerStateVmInstanceView.code = "PowerState/running";
          vms[i].switchState = getSwitchState(powerStateVmInstanceView);
          setVms([...vms]);
        });
      }
    }
  };

  const getSessionCount = (targetResourceId: string | undefined): number => {
    // if(!targetResourceId || !vmActiveSessionsHashTable[targetResourceId]){
    //   return 0;
    // }
    // return vmActiveSessionsHashTable[targetResourceId].length;

    //Mocked output until library bug is fixed
    return 2;
  };

  const getSessionUser = (targetResourceId: string | undefined): string[] => {
    // if(!targetResourceId || !vmActiveSessionsHashTable[targetResourceId]){
    //   return [];
    // }
    // return vmActiveSessionsHashTable[targetResourceId].map(sessionInfo => sessionInfo.userName ?? "Unknown User");

    //Mocked output until library bug is fixed
    return ["VmUser", "max.musterman@existiert.net"];
  };

  const vmTableBody = useMemo(() => {
    if (!vms) {
      return <></>;
    }
    return vms
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((vmRow, index) => {
        return (
          <TableRow key={vmRow.vm?.name}>
            <TableCell>
              {vmRow.vm?.name?.replaceAll(vmDisplayNameRemoveRegex, "")}
            </TableCell>
            <TableCell align="left">
              <FormControlLabel
                control={
                  <Switch
                    {...vmRow.switchState}
                    onChange={() => {
                      handleSwitchChange(index);
                    }}
                  />
                }
                label={vmRow.vmInstanceView?.code ?? "none"}
              ></FormControlLabel>
            </TableCell>
            <TableCell align="right">
              <Button
                variant="contained"
                disabled={
                  vmRow.pendingRdpGeneration ||
                  (vmRow.vmInstanceView &&
                    vmRow.vmInstanceView.code &&
                    vmRow.vmInstanceView?.code.toLowerCase() ===
                      "powerstate/running")
                    ? false
                    : true
                }
                onClick={() => {
                  try { 
                    generateCliCommand(vmRow);
                  } catch (e) {
                    setError({err:true, errorInfo: e.message});
                  }
                }}
                >
                  {vmRow.pendingRdpGeneration ? (
                  <CircularProgress
                    sx={{ marginRight: "1rem" }}
                    color="inherit"
                    size={20}
                  />
                ) : null}
                Copy CLI command
                </Button>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                  <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
                    Copied CLI command to Clipboard
                  </Alert>
                </Snackbar>
            </TableCell>
            <TableCell align="right">
              <Button
                variant="contained"
                disabled={
                  vmRow.pendingRdpGeneration ||
                  (vmRow.vmInstanceView &&
                    vmRow.vmInstanceView.code &&
                    vmRow.vmInstanceView?.code.toLowerCase() ===
                      "powerstate/running")
                    ? false
                    : true
                }
                onClick={() => {
                  try { 
                    generateRdp(vmRow);
                  } catch (e) {
                    setError({err:true, errorInfo: e.message});
                  }
                }}
              >
                {vmRow.pendingRdpGeneration ? (
                  <CircularProgress
                    sx={{ marginRight: "1rem" }}
                    color="inherit"
                    size={20}
                  />
                ) : null}
                Generate
              </Button>
            </TableCell>
            {/* <TableCell align="right">
              <Tooltip
                placement="left"
                TransitionComponent={Zoom}
                title={
                  <>
                    {getSessionUser(vmRow.vm.id).map((usr) => (
                      <div>{usr}</div>
                    ))}
                  </>
                }
                arrow
              >
                <Badge
                  badgeContent={getSessionUser(vmRow.vm.id).length}
                  color="warning"
                >
                  <PeopleAltIcon color="action" />
                </Badge>
              </Tooltip>
            </TableCell> */}
          </TableRow>
        );
      });
  }, [vms, vmActiveSessionsHashTable, open, handleSwitchChange]);


  return (
    <Container maxWidth={false} sx={{ paddingTop: "1rem" }}>
      {error.err ? <Alert severity="error" onClose={() => {setError({err: false, errorInfo: ""})}}> An error ocurred: {error.errorInfo}</Alert> : null}
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Autocomplete
              sx={{ width: "100%" }}
              loading={subscriptions === undefined}
              getOptionLabel={(option) => {
                let label = option.displayName ?? "";
                label = label.replaceAll(
                  subscriptionDisplayNameRemoveRegex,
                  ""
                );
                return label;
              }}
              options={subscriptions ?? []}
              isOptionEqualToValue={(option, value) =>
                option.subscriptionId === value.subscriptionId
              }
              onChange={(event, value) => {
                updateResourceGroups(value ?? undefined);
              }}
              value={selection.subscription ?? (defaultSubscription ?? null)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Subscriptions"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {subscriptions === undefined ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              sx={{ width: "100%" }}
              loading={resourceGroups === undefined}
              getOptionLabel={(option) => {
                let label = option.name ?? "";
                label = label.replaceAll(
                  resourceGroupDisplayNameRemoveRegex,
                  ""
                );
                return label;
              }}
              options={resourceGroups ?? []}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, value) => {
                updateVMs(selection.subscription, value ?? undefined);
              }}
              onOpen={() => {
                updateResourceGroups(selection.subscription ?? defaultSubscription)
              }}
              value={selection.resourceGroup ?? null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Customer (Resource Groups)"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {resourceGroups === undefined ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={2}
            justifyContent="flex-end"
            alignItems="center"
          >
            <TextField
              select
              label="Location Filter"
              defaultValue="Show All"
              variant="standard"
              fullWidth
              SelectProps={{
                multiple: true,
                value: locationFilter,
                onChange: handleLocationSwitch
              }}
            >
              {locations.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}

            </TextField>
          </Grid> 
          
          <Grid item xs={12}>
            {selection.resourceGroup && (
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    sx={{ tableLayout: "fixed" }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>VM Name</TableCell>
                        <TableCell align="left">VM State</TableCell>
                        <TableCell align="right">CLI Command</TableCell>
                        <TableCell align="right">RDP</TableCell>
                        {/* <TableCell align="right">Active Connections</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>{vmTableBody}</TableBody>
                  </Table>
                </TableContainer>
                {!vms && (
                  <div className="basion-gui__table-spinner">
                    <CircularProgress />
                  </div>
                )}
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={vms && vms.length ? vms.length : 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            )}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
