import environment from "../environmentSettings.json";
import {
  TVMDetailedInformation,
  TVMDetailedInformationSwitchState,
} from "./types";

//azure-sdk-js
import {
  ComputeManagementClient,
  InstanceViewStatus,
  VirtualMachineInstanceView,
} from "@azure/arm-compute";
import { ResourceGroup, ResourceGroupsListOptionalParams, ResourceManagementClient } from "@azure/arm-resources";
import {
  Subscription,
  SubscriptionClient,
} from "@azure/arm-resources-subscriptions";
import { InteractiveBrowserCredential } from "@azure/identity";
import {
  BastionActiveSession,
  NetworkManagementClient,
} from "@azure/arm-network";

//MUI
import { SwitchPropsColorOverrides } from "@mui/material/Switch";
import { OverridableStringUnion } from "@mui/types";


const managementApiCredential = new InteractiveBrowserCredential({
  tenantId: environment.tenantId,
  clientId: environment.clientId,
});

const resourceGroupFilterString = environment.resourceGroupFilterString

// See https://learn.microsoft.com/en-us/rest/api/virtualnetwork/get-active-sessions/get-active-sessions?tabs=JavaScript
export const getActiveBastionSessions = async (
  subscriptionId: string,
  resourceGroupName: string,
  bastionHostName: string
): Promise<BastionActiveSession[]> => {
  const networkClient = new NetworkManagementClient(
    managementApiCredential,
    subscriptionId
  );
  const resArray = new Array();

  for await (const item of networkClient.beginListActiveSessionsAndWait(resourceGroupName, bastionHostName)) {
    resArray.push(item);
  }
  
  return resArray;
};

export const startVm = async (
  subscriptionId: string,
  resourceGroupName: string,
  vmName: string
): Promise<void> => {
  const computeClient = new ComputeManagementClient(
    managementApiCredential,
    subscriptionId
  );

  await computeClient.virtualMachines.beginStartAndWait(
    resourceGroupName,
    vmName
  );
};

export const stopVm = async (
  subscriptionId: string,
  resourceGroupName: string,
  vmName: string
): Promise<void> => {
  const computeClient = new ComputeManagementClient(
    managementApiCredential,
    subscriptionId
  );

  await computeClient.virtualMachines.beginDeallocateAndWait(
    resourceGroupName,
    vmName
  );
};

export const getVmInstanceView = async (
  subscriptionId: string,
  resourceGroupName: string,
  vmName: string
): Promise<VirtualMachineInstanceView> => {
  const computeClient = new ComputeManagementClient(
    managementApiCredential,
    subscriptionId
  );

  return await computeClient.virtualMachines.instanceView(
    resourceGroupName,
    vmName
  );
};

export const getSubscriptions = async (): Promise<Subscription[]> => {
  const subClient = new SubscriptionClient(managementApiCredential);
  const resultList: Subscription[] = [];
  for await (const subscription of subClient.subscriptions.list()) {
    resultList.push(subscription);
  }
  return resultList;
};

export const getResourceGroups = async (
  subscriptionId: string
): Promise<ResourceGroup[]> => {
  const resourceClient = new ResourceManagementClient(
    managementApiCredential,
    subscriptionId
  );
  const resultList: ResourceGroup[] = [];
  for await (const resourceGroup of resourceClient.resourceGroups.list()) {
    resultList.push(resourceGroup);
  }
  return resultList.filter(resource => {return resource.name?.includes(resourceGroupFilterString)});
};

export const getSwitchState = (
  powerStateInstanceView: InstanceViewStatus
): TVMDetailedInformationSwitchState => {
  let vmState = powerStateInstanceView?.code?.toLowerCase();

  let switchColor:
    | OverridableStringUnion<
        | "success"
        | "warning"
        | "primary"
        | "secondary"
        | "error"
        | "info"
        | "default",
        SwitchPropsColorOverrides
      >
    | undefined;
  switch (vmState) {
    case "powerstate/running":
      switchColor = "success";
      break;
    case "powerstate/starting":
    case "powerstate/deallocating":
      switchColor = "warning";
      break;
    default:
      switchColor = undefined;
  }

  return {
    lable: vmState,
    checked:
      vmState === "powerstate/running" || vmState === "powerstate/starting",
    disabled: !(
      vmState === "powerstate/running" || vmState === "powerstate/deallocated"
    ),
    color: switchColor,
  };
};

export const getVMsDetailedInformation = async (
  subscription: Subscription,
  resourceGroup: ResourceGroup
): Promise<TVMDetailedInformation[]> => {
  const computeClient = new ComputeManagementClient(
    managementApiCredential,
    subscription.subscriptionId!
  );
  const resultList: TVMDetailedInformation[] = [];

  for await (const resource of computeClient.virtualMachines.list(
    resourceGroup.name!
  )) {
    if (
      !subscription ||
      !subscription.subscriptionId ||
      !resourceGroup ||
      !resourceGroup.name ||
      !resource ||
      !resource.name
    ) {
    } else {
      let vmInstanceView = await getVmInstanceView(
        subscription.subscriptionId!,
        resourceGroup.name!,
        resource.name!
      );

      let powerStateInstanceView = vmInstanceView?.statuses?.filter((element) =>
        element.code?.startsWith("PowerState")
      )[0]!;

      let vmDetailedInformation: TVMDetailedInformation = {
        pendingRdpGeneration: false,
        resourceGroup: resourceGroup,
        subscription: subscription,
        vm: resource,
        vmInstanceView: powerStateInstanceView,
        switchState: getSwitchState(powerStateInstanceView),
      };
      resultList.push(vmDetailedInformation);
    }
  }
  return resultList;
};

export const getVMDetailedInformation = async (
  subscription: Subscription,
  resourceGroup: ResourceGroup,
  vmName: string
): Promise<TVMDetailedInformation | undefined> => {
  const computeClient = new ComputeManagementClient(
    managementApiCredential,
    subscription.subscriptionId!
  );

  let vmToReturn: TVMDetailedInformation | undefined = undefined;

  const resource = await computeClient.virtualMachines.get(
    resourceGroup.name!,
    vmName
  );
  if (
    subscription &&
    subscription.subscriptionId &&
    resourceGroup &&
    resourceGroup.name &&
    resource &&
    resource.name
  ) {
    let vmInstanceView = await getVmInstanceView(
      subscription.subscriptionId!,
      resourceGroup.name!,
      resource.name!
    );

    let powerStateInstanceView = vmInstanceView?.statuses?.filter((element) =>
      element.code?.startsWith("PowerState")
    )[0]!;

    vmToReturn = {
      pendingRdpGeneration: false,
      resourceGroup: resourceGroup,
      subscription: subscription,
      vm: resource,
      vmInstanceView: powerStateInstanceView,
      switchState: getSwitchState(powerStateInstanceView),
    };
  }
  return vmToReturn;
};
