import { Menu } from "./components/Menu";
import { BastionGui } from "./pages/BastionGui";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import { ErrorBoundary } from "./components/ErrorBoundary";

export const App = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
          secondary: {
            main: '#FFFFFF',
          }
        },
      }),
    [prefersDarkMode],
  );
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Menu />
      <BastionGui />
    </ThemeProvider>
  );
};
