import { InteractiveBrowserCredential } from "@azure/identity";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import environment from "../../assets/environmentSettings.json";

let msalInstance: PublicClientApplication | undefined;

export const getAdminAccessToken = async (): Promise<string> => {
  var msalAdminPageInstance = getMsalInstance();
  const accounts = msalAdminPageInstance.getAllAccounts();

  if (accounts.length === 0) {
    throw "User not signed in";
  }
  const request = {
    scopes: [`https://management.azure.com/user_impersonation`],
    account: accounts[0],
  };

  const authResult = await msalAdminPageInstance
    .acquireTokenSilent(request)
    .catch((error: Error) => {
      console.warn("acquire token silently failed", error);
      msalAdminPageInstance.acquireTokenRedirect({
        scopes: [`https://management.azure.com/user_impersonation`],
      });
    });

  if (authResult) {
    return authResult.accessToken;
  } else {
    throw "Auth not successful";
  }
};

export const getMsalInstance = () => {
  if (!msalInstance) {
    var msalConfig: Configuration = {
      auth: {
        clientId: environment.clientId,
        authority: `https://login.microsoftonline.com/${environment.tenantId}`,
        postLogoutRedirectUri: `${environment.logoutRedirectUri}`
      },
    };
    msalInstance = new PublicClientApplication(msalConfig);
  }
  return msalInstance;
};

