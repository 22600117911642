import { InteractionType } from "@azure/msal-browser";
import { PublicClientApplication } from "@azure/msal-browser/dist/app/PublicClientApplication";
import {
  AuthenticatedTemplate,
  MsalAuthenticationTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { useEffect, useState } from "react";
import { getMsalInstance } from "../assets/ts/authFunctions";
import { App } from "../App";

export const MsalAuthenticatedApp = () => {
  const [msalInstance, setMsalInstance] = useState<PublicClientApplication>();

  useEffect(() => {
    setMsalInstance(getMsalInstance());
  }, []);

  if (msalInstance) {
    return (
      <MsalProvider instance={msalInstance}>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={{
            scopes: [`https://management.azure.com/user_impersonation`],
          }}
        >
          <AuthenticatedTemplate>
            <App />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <p>You are not signed in! Please sign in.</p>
          </UnauthenticatedTemplate>
        </MsalAuthenticationTemplate>
      </MsalProvider>
    );
  } else {
    return <p>Initializing...</p>;
  }
};
